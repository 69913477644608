import styled from "styled-components";

export const ModalHome = styled.div`
        font-family: "Ubuntu", sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 9999999999;
    &.close{
        display: none;
    }
    .bem-vindo{
        display: flex;
        flex-direction: column;
        width: 79.6vw;
        max-width: 67.5rem;
        height: clamp(31.25rem , 45vw, 38.438rem);
        background-color: var(--modal-bg-2);
        background-repeat: no-repeat;
        background-position: center;
        background-size: inherit;
        margin-top: 9.6rem;
        box-shadow: 0 0 2rem -1rem var(--black);
        &:after {
            margin-left: min(47%, 40rem);
            content: ' ';
            border-top: clamp(15.625rem , 22.5vw, 19.3rem) solid var(--modal-bg-1);
            border-bottom: clamp(15.625rem , 22.6vw, 19.3rem) solid var(--modal-bg-1);
            border-left: min(11.74vw, 160.37px) solid transparent;
            border-right: min(21vw, 18.75rem) solid var(--modal-bg-1);
            width: 0;
            position: absolute;
        }
    }
    .img-modal-container {
        position: absolute;
        justify-content: flex-end;
        left: calc(50% - 33.750rem);
        top: 9.6rem;
        padding-right: min(10vw, 6rem);
        position: absolute;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        max-width: 67.5rem;
        max-height: 38.438rem;

    }
    .img-modal {
        z-index: 2;
        position: absolute;
        margin: auto;
        width: clamp(20rem,32.504vw,27.75rem);
        aspect-ratio: calc(572/462)
    }
    .icon-mobile{
            display: none;
        }
        .close-modal-icon{
            z-index: 10;
            width: 1.875rem;
            align-self: flex-end;
            margin-right: 1.875rem;
            margin-top: 1.875rem;
        }
        img{
            height: auto;
            cursor: pointer;
        }
        picture {
            display: flex;
            flex-direction: column;
        }
    .txt-modal{
        max-width: 40vw;
        margin-top: 2rem;
        & > h1, p, button {
            margin-left: 3.4rem;
        }
        h1{
            min-width: 40ch;
            font-family: "Arvo", serif;
            font-weight: bold;
            font-size: 2.5rem;
            line-height: 3rem;
            color: var(--modal-title);
        }
        p{
            max-width: 38ch;
            font-family: sans-serif;
            margin-top: 1.2rem;
            font-weight: 400;
            font-size: min(1.611vw, 1.375rem);
            line-height: 2.2rem;
            color: var(--modal-description);
        }
        a {
            display: block;
            position: relative;
            width: 100%;
            height: 100%;
            button{
                background-color: var(--modal-btn-bg-1);
                color: var(--modal-btn-text-1);
                font-family: "Arvo", serif;
                width: min(30vw, 28.625rem);
                height: min(8vw, 5.3rem);
                border-radius: 50px;
                border: none;
                font-weight: bold;
                font-size: 2.5rem;
                line-height: 3rem;
                margin-top: 2.5rem;
                text-transform: uppercase;
            }
        }
    }
    @media(max-width: 60em) {
        & > div {
            display: flex;
        }
        .bem-vindo{
            align-self: flex-start;
            margin-top: 4.4rem;
            width: 18.8rem;
            height: 27.1rem;
            p {
                margin-left: 0;
                max-height: 100%;
            }
            h1 {
                margin-left:  0;
            }
            button {
                margin-top: 0;
            }
            a {
                margin-left: 58%;
            }
        }
        .bem-vindo:after {
            margin-left: 0;
            margin-top: 14.5rem;
            border-top: 5rem solid transparent;
            border-bottom: 7.7rem solid var(--modal-bg-1);
            border-left: 9.45rem solid var(--modal-bg-1);
            border-right: 9.45rem solid var(--modal-bg-1);
        }
        .img-modal-container {
            padding-left: 0;
            width: 18.8rem;
            height: 27.1rem;
            margin-left: 3rem;
            margin-top: 13rem;
            left: unset;
            padding-right: unset;
            top: unset;
            justify-content: unset;
            align-items: unset;
        }
        .img-modal {
            width: 13rem;
        }
        .close-modal-icon{
            width: 1.3rem;
            align-self: flex-end;
            margin-right: 1.3rem;
            margin-top: 1.3rem;
            height: auto;
        }
        .txt-modal{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            max-width: 100%;
            height: 100%;
            margin-top: 0;
            & > h1, p, button {
                margin-left: 2.9rem;
            }
            a {
                button {
                    position: relative;
                    z-index: 5;
                    font: normal normal 700 0.638rem/0.688rem "Ubuntu";
                    background-color: var(--modal-btn-bg-2);
                    color: var(--modal-btn-text-2);
                    min-width: 0;
                    margin-top: 12rem;
                    width: 7.9rem;
                    height: 1.6rem;
                    margin-left: 0;
                }
            }
            p {
                font-family: "Ubuntu", sans-serif;
                font-size: 0.813rem;
                text-align: left;
                line-height: 1rem;
                max-width: 30ch;
            }
            h1 {
                font-family: "Ubuntu", sans-serif;
                font-size: 1.06rem;
                line-height: 0.7rem;
                margin-top: 1rem;
            }
            a{
            align-self: center;
            width: 100%;
            height: 35px;
            font-size: 1.1rem;
            line-height: 2.2rem;
            }
        }
}`;

export const Banner = styled.div`
    box-sizing: border-box;
    width: 100%;
    position: relative;
    min-height: 33.125rem;
    .carousel{
        width: 100vw;
    }
    .carousel-item {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 33.125rem;
        img{
            position: sticky;
            top: 0;
            left: 0;
            object-fit: cover;
            min-height: 31.25rem;
            position: absolute;
            width: 100vw;
        }
    }
    .texto{
        margin: 9.125rem;
        position: relative;
        width: clamp(18.75rem, 39.312vw, 60ch);
        h1{
            font-family: 'ubuntu';
            font-weight: bold;
            font-size: clamp(1.375rem, 2.928vw, 2.5rem);
            line-height: 2.875rem;
            color: var(--banner-title);
            margin-bottom: 0.7rem;
        }
        p{
            font-family: 'ubuntu';
            font-weight: 400;
            font-size: clamp(0.813rem, 1.757vw, 1.5rem);
            line-height: clamp(1rem, 2.2vw,1.875rem);
            color: var(--banner-description);
        }
    }
    @media (max-width: 30em) {
        min-height: 26.5rem;
        .carousel-item {
            min-height: 23.438rem;              
            img {
                min-height: 21.875rem;              
            }
            .texto {
                align-self: flex-start;
                padding-top: 0.738rem;
                text-align: center;
                margin: auto;
                h1 {
                    margin-bottom: 0.2rem;
                    margin-top: 0.1rem;
                }
            }
        }
        padding-top: 3.75rem
    }
`;

export const Rede = styled.div`
    background-color: var(--primary);
    @media (max-width: 80em) {
        margin-top: 7rem;
        padding-top: 6rem;
    }
    text-align: center;
    .container {
        max-width: 100%;
        .row {
            overflow: hidden;
            max-width: 100%;
            display: flex;
            margin-inline: 0;
            div {
                flex-shrink: 0;
                flex-wrap: wrap;
                flex-grow: 1;
            }
            .col-md-2 {
                padding-right: 0;
                padding-left: 0;
                img {
                    margin-top: 2.5rem;
                }
                p {
                    margin-top: 1rem;
                }
            }
            @media(max-width: 60rem) and (min-width: 48rem) {
                .col-md-2 {
                    width: 30%;
                }
            }
        }
    }
    &&& form {
        button {
            background-color: var(--search-icon-bg);
            position: relative;
            z-index: 20;
            svg {
                fill: var(--search-icon-txt);
            }
        }
        select {
            z-index: 20;
        }
    }
    h1{
        color: var(--secondary-text);
        font-family: 'ubuntu';
        font-weight: bold;
        font-size: 1.875rem;
        line-height: 2.5rem;
        text-align: center;
        text-transform: uppercase;
        padding-top: 2rem;
        margin-bottom: 1rem;
        span{
            color: var(--secondary);
        }
        @media (max-width: 48rem){
            font-size: 1.063rem;
            line-height: 1.25rem;
            margin-bottom: 1.875rem;
        }
    }
    p{
        font-family: 'ubuntu';
        font-weight: 500;
        color: var(--secondary-text);
        font-size: 14px;
        line-height: 18px;
        span{
            font-weight: bold;
        }
    }
    @media (max-width: 48em) {
        margin-top: 49vw;
        padding-top: 30rem;
    }
    @media (max-width: 30em) {
        margin-top: clamp(46rem,126vw,47rem);
        padding-top: clamp(4rem,11vw,10rem);    
    }
`;

export const Perguntas = styled.div`
    padding: 0 0 3.75rem 0;
    background-color: var(--primary);
    height: 100%;
    overflow: visible;

    .linear-gradient-decoration {
        background: transparent linear-gradient(0deg,var(--primary) 70%,var(--primary-with-alpha) 100%) 0% 0% no-repeat padding-box;
        height: 13rem;
        margin-top: 0;
        top: 2rem;
        z-index: 0;
        width: 100vw;
        position: relative;
    }

    .container {
        position: relative;
    }
    div.col-md-6 {
        margin-top: 3rem;
        @media (max-width: 60em) {
            margin-top: 0;
        }
    }
    picture {
        position: absolute;
        overflow: visible;
        img {
            display: block;
            top: -0.5rem;
            width: 100%;
            position: relative;
            z-index: 0;
        }
    }

    @media (max-width: 80em) {
        picture {
            position: relative;
            overflow: hidden;
            z-index: 0;
            margin-top: -10rem;
            z-index: 0;
            object-fit: cover;
            margin-left: clamp(-19rem,-13vw,-18rem);
            img {
                width: 100%;
                object-position: center;
                z-index: 0;
                overflow: hidden;
                object-fit: cover;
            }
        }
        padding: 5rem 0 3.75rem 0;
        .container {
            margin-top: -115vw;
        }
        .linear-gradient-decoration {
            height: 8rem;
            position: relative;
            top: unset;
        }
    }

    @media (max-width: 48em) {
        margin-top: 221vw;
        position: relative;
        .container {
            margin-top: -144vw;
        }
        picture {
            margin-top: unset;
            img {
                top: 4.5rem;
                width: 150%;
                object-position: center;
            }
        }
        .linear-gradient-decoration {
            top: -4rem;
            height: 14rem;
        }
    }
    @media (max-width: 30em) {
        .linear-gradient-decoration {
            top: unset;
        }
    }


    .accordion-item {
        color: var(--primary-text);
        .accordion-header button .acordion-titulo {
            color: var(--primary-text);
        }
    }
    @media (max-width: 768px){
        padding: 30px 0;
        .accordion-body{
            font-size: 14px;
        }
    }
    .texto{
        position: relative;
        width: 300px;
        margin: 0 auto;
        z-index: 2;
        h1{
            font-family: 'ubuntu';
            font-weight: bold;
            color: var(--secondary-text);
            font-size: 40px;
            line-height: 46px;
            @media (max-width: 768px){
                font-size: 17px;
                line-height: 19px;
                text-align: center;
                margin-bottom: 30px;
            }
        }
    }
    .acordion-item{
        margin-bottom: 24px;
        border-radius: 10px;
        border: none;
        background-color: #cfcfcfdd;
        font-size: 0.875rem;
        font-family: "Ubuntu", sans-serif;
        line-height: 1rem;
        position: relative;
        z-index: 2;
        button{
            border-top-left-radius: 10px!important;
            border-top-right-radius: 10px!important;
            border-bottom-left-radius: 10px!important;
            border-bottom-right-radius: 10px!important;
            background-color: #ffffff00;
            box-shadow: unset;
            :hover{
                font-weight: 900;
            }
            :after {
                background-image: none;
            }
        }
        @media (max-width: 48rem) {
            .accordion-body{
                padding-left: 2rem;
                padding-top: 0;
            }
        }
    }
    .acordion-titulo{
        text-align: center;
        width: 90%;
        margin: auto;
        @media (max-width: 768px){
            font-size: 0.875rem;
            text-align: left;
            margin-left: 0.2rem;
        }
        :not(.collapsed) {
            color: #212529;
        }
    }
    @media (min-width: 100em) {
        .background-container {
            display: flex;
            justify-content: center;
        }
    }

    @media (max-width: 48em) {
        margin-top: -10rem;
    }
`;

export const Unidades = styled.div`
    background-color: var(--primary);
    padding: 30px 0;
    .unidade{
        background-color: var(--secondary);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 366px;
        text-align: center;
        border: solid 1px #CCCCCC;
        padding: 20px 40px;
        margin: 20px 0;
        h4{
            font-family: 'ubuntu';
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            margin: 20px 0;
        }
        a{
            color: #000;
            font-family: 'ubuntu';
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-decoration: none;
        }
        button, a.local {
            min-width: 200px;
            line-height: 24px;
            border-radius: 30px;
            background-color: var(--btn-primary-txt);
            border: none;
            color: var(--btn-primary-bg);
            margin-top: 20px;
            position: relative;
            padding: 10px 0;
            img{
                position: absolute;
                right: 20px;
            }
        }
        .logo {
            display: block;
            margin: 0 auto;
            overflow: hidden;
            border: 1px solid #ccc;
            border-radius: 50%;
            width: 92px;
            height: 92px;
        }
    }
`;

export const Mascote = styled.div`

    position: absolute;
    margin-top: -1rem;
    width: 100%;
    z-index: 20;
    display: flex;
    justify-content: center;
    overflow: visible;
    padding-bottom: 6rem;
    p {
        margin-top: 3rem;
        position: absolute;
        font-family: 'Ubuntu';
        position: absolute;
        text-align: center;
        font-weight: 700;
        font-size: clamp(1rem, 3vw, 1.5rem);
        color: var(--secondary-text)
    }
    picture {
        margin-top: 4rem;
        z-index: 1;
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        img {
            margin-top: 2rem;
        }
    }

    @media (max-width: 80em) {
        .linear-gradient-decoration {
            position: absolute;
            top: unset;
            bottom: 0;
        }

    }

    @media (max-width: 48em) {
        z-index: 0;
        .linear-gradient-decoration {
            margin-top: -8rem;
            position: absolute;
            top: 25rem;
            top: 0rem;
            z-index: -1;
            height: 8rem;
        }
        picture {
            background-color: var(--primary);
        }
    }
    @media (max-width: 80em) {
        position: relative;
        margin-top: 0;
        p {
            margin-top: 0;
            z-index: 10;
        }
        picture {
            z-index: 1;
            position: relative;
            margin-top: 0;
        }
    }

    @media (max-width: 30em) {
        .linear-gradient-decoration {
            margin-top: -10rem;
            position: absolute;
            top: 25rem;
            top: 0rem;
            z-index: -1;
            height: 35rem;
        }
        picture {
            background-color: var(--primary);
        }
    }
`
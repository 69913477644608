import { ReactElement } from "react";
import educacao from '../../../assets/svgs/icone-educacao.svg';
import lazer from '../../../assets/svgs/icone-lazer.svg';
import saude from '../../../assets/svgs/icone-saude.svg';
import { useCampaign } from "../../../context/CampaignContext";
import { DefaultButton } from "../../DefaultButton";
import decathlon from './assets/logo-decathlon.png';
import golStore from './assets/logo-golstore.png';
import * as S from './style';

const cards = [
    { logo: golStore, alt: 'Gol Store', title: '10% de desconto', paragraph: 'Na loja online via cupom enviado para os filiados.'},
    { logo: decathlon, alt: "Decathlon", title: '5% de desconto', paragraph: "Na loja online via cupom enviado para os filiados."},
    { logo: saude, alt: "Saúde", title: 'saúde', paragraph: "Descontos em consultas e exames. Consultas a partir de R$ 26 em clínicas parceiras"},
    { logo: lazer, alt: "Lazer", title: 'lazer', paragraph: "Descontos em supermercados, restaurantes e mais"},
    { logo: educacao, alt: "Educação", title: 'educacao', paragraph: "30 dias gratuitos na Refuturiza: educação + empregabilidade"},
]

export function BeneficiosSection (): ReactElement {
  const { campaignData } = useCampaign()
  return <S.Beneficios>
    <S.SectionTitle> Conheça os benefícios de ter o Cartão de TODOS ABC!</S.SectionTitle>
    <S.BeneficiosContainer>
      <div>
        {cards && cards.map(card => <S.Card>
            <S.Logo src={card.logo} alt={card.alt} />
            <S.Title>{card.title}</S.Title>
            <S.Paragraph>{card.paragraph}</S.Paragraph>
        </S.Card>)}
      </div>
    </S.BeneficiosContainer>
    <S.CTALink href={campaignData.salesUrl}>
        <DefaultButton>Peça já o seu</DefaultButton>
    </S.CTALink>
  </S.Beneficios>
}
import { Link } from "react-router-dom";
import styled from "styled-components";

type IProps = {
    translucent: boolean;
}

const HeaderBase = styled.div`
    overflow: hidden;
    padding: 20px 0;
    position: fixed;
    width: 100%;
    z-index: 9999999999999;
    .grid{
        display: flex;
        justify-content: space-between;
    }
    .grid > div:nth-child(2) {
            width: 100%;
            display: flex;
            align-items: flex-end;
            margin-left: 0.625rem;
            @media(max-width: 71.25rem) {
                margin-left: 0;
            }
    }
    .desk{
        display: flex;
        padding-left: clamp(3rem, 21.5vw, 21.5rem);
        justify-content: flex-end;
        width: 100%;
        align-items: baseline;
        a{
            text-decoration: none;
            color: var(--header-menu-tooltip);
            p{
                font-family: "Ubuntu", sans-serif;
                font-weight: bold;
                color: var(--secondary-text);
                font-size: 0.875rem;
                line-height: 1rem;
                padding-top: 7px;
            }
        }
        a.btn-desk{
            button{
                background-color: var(--btn-secondary-bg);
                color: var(--btn-secondary-txt);
                margin-left: 0.8rem;
                width: 15.375rem;
                font-size: 1.25rem;
                height: 2.75rem;
            }
        }
    }
    .header-container {
        max-width: 69.25rem;
        margin: 0 auto;
        @media (max-width: 72rem) {
            padding: 0 1.25rem;
        }
    }
    @media (max-width: 65rem) {
        .desk {
            padding-left: clamp(3rem, 7vw, 8rem);
            justify-content: space-evenly;
            a.btn-desk{
                button{
                    width: 10.375rem;
                    font-size: 1rem;
                    height: 2.75rem;
                }
            }
        }
    }
    @media (max-width: 48rem){
        padding: 13px 0;
        position: fixed;
        a > img {
           width: 9.375rem;
        }
        .bt-mobile > img {
            margin-top: 0.25rem;
        }
        .mobile {
            display: flex;
            justify-content: center;
            @media (max-width: 30em) {
                justify-content: flex-end;
            }
        }
        .desk{
            display: none;
        }
    }
    @media (min-width: 48rem){
        .mobile{
            display: none;
        }
    }
    .aberto{
        transform: translateX(0);
    }
`

export const Header = styled(HeaderBase)<IProps>.attrs<IProps>(({ translucent }: IProps) => {
    const styles = getComputedStyle(document.body);
    const primaryColor = styles.getPropertyValue('--primary')
    const backgroundSelected = translucent ? `${primaryColor}00` : primaryColor;
    const position = translucent ? "absolute" : "fixed";

    return {
        style: {
            backgroundColor: backgroundSelected,
            position: position,
            transition: "filter 0.5s ease",
        }
    } as unknown as IProps;
})<IProps>`
    .responsive-logo {
        margin-left: 1rem;
        width: clamp(160px, 19vw, 270px);
    }
    .desk {
        a.btn-desk{
            button{
                background-color: ${(props) => props.translucent ? "var(--btn-secondary-bg)" : "var(--btn-secondary-bg)"};
                color: ${(props) => props.translucent ? "var(--btn-secondary-txt)" : "var(--btn-secondary-txt)"};
                margin-left: 0.8rem;
                width: 15.375rem;
                font-size: 1.25rem;
                height: 2.75rem;
            }
        }
    }
`;

export const HeaderLink = styled(Link)`
    transition: .3s ease-in-out;
    &:hover {
        border-bottom: 10px solid ;
    }
`

export const Modal = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    background: var(--primary);
    width: calc(100vw - 74px);
    height: 100vh;
    transform: translateX(calc(100% + 74px));
    transition: .3s ease-in-out;
    img{
        position: absolute;
        right: 40px;
        top: 40px;
    }
    .bts{
        display: block;
        padding: 0;
        padding-top: clamp(5rem, 25vmin, 10rem);
        text-align: center;
        a{
            text-decoration: none;
            p{
                font-family: 'ubuntu';
                font-weight: bold;
                color: var(--secondary);
                font-size: 1.375rem;
                line-height: 1.625rem;
                text-transform: uppercase;
                padding: 1.5rem 0;
            }
        }
        button{
            min-width: 16.875rem;
            min-height: 3rem;
            background-color: var(--btn-secondary-bg);
            font-family: 'ubuntu';
            font-weight: bold;
            font-size: 22px;
            color: var(--btn-secondary-txt);
            margin-top: 80px;
            border: none;
            border-radius: 30px;
            @media (max-height: 26.25rem) {
                margin-top: 1.25rem;
            }
        }
    }
`;